var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { staticClass: "iq-radio", attrs: { label: _vm.label, prop: _vm.prop } },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "radio-group-filter iq-radio",
          attrs: { disabled: _vm.disabled },
          model: {
            value: _vm.inputVal,
            callback: function ($$v) {
              _vm.inputVal = $$v
            },
            expression: "inputVal",
          },
        },
        _vm._l(_vm.options, function (item) {
          return _c(
            "el-radio",
            {
              key: item.id || item,
              staticClass: "iq-radio",
              attrs: { label: _vm.keyLabel ? item[_vm.keyLabel] : item },
            },
            [_vm._v(" " + _vm._s(_vm.keyText ? item[_vm.keyText] : item) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }