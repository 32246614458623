<template>
  <div
    v-loading="getLoading || isLoading"
    element-loading-text="Загрузка..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(255, 255, 255, 0.8)"
  >
    <iq-title :class="{ desktopHeader: !isMobile, mobileHeader: isMobile }">
      Получение тайм слотов:
    </iq-title>
    <balance-left
      v-if="timeslotCountTerminal > 0"
      :count-left="(getUserProfile && getUserProfile.timeslots_left) || 0"
      @addBalance="addBalaceRequestHandle"
    />

    <el-form
      ref="gettingSlotsForm"
      :model="gettingSlotsForm"
      :rules="rulesFormGettingSlots"
      label-width="300px"
      class="drivers-slots-form"
      label-position="top"
      @submit.native.prevent
    >
      <iq-select-form
        v-model.number="gettingSlotsForm.unload_id"
        full-width
        prop="unload_id"
        label="Выберите терминал:"
        placeholder="Укажите терминал"
        :options="getTerminals"
      />
      <iq-select-form
        v-if="gettingSlotsForm.unload_id && getExporters.length !== 1"
        v-model.number="gettingSlotsForm.exporter_id"
        full-width
        prop="exporter_id"
        label="Выберите экспортера:"
        placeholder="Укажите экспортера"
        :options="getExporters"
        @input="selectSuppliers"
      />
      <el-collapse-transition>
        <div v-if="gettingSlotsForm.exporter_id">
          <iq-radio-form
            v-model.number="gettingSlotsForm['requests_count']"
            label="Количество грузовиков (не более 5):"
            prop="requests_count"
            :options="[1, 2, 3, 4, 5]"
          />
          <el-form-item v-if="getEditorText">
            <el-card class="drivers-slot__card" v-html="getEditorText" />
          </el-form-item>

          <div class="m-t-20 m-b-20 drivers-slot__warning">
            ДАТА И ВРЕМЯ ПРИБЫТИЯ ПО МЕСТНОМУ ВРЕМЕНИ ТЕРМИНАЛА!
          </div>

          <iq-select-date-form
            v-model.number="gettingSlotsForm['day']"
            size="fullWidth"
            label="Дата прибытия:"
            prop="day"
            type="date"
            placeholder="Укажите день"
            format="dd/MM/yyyy"
            :options="pickerOptions"
          />
          <iq-select-form
            v-model="gettingSlotsForm['time']"
            full-width
            label="Время прибытия:"
            prop="time"
            :message="'Для выбора времени укажите день'"
            :disabled="!gettingSlotsForm['day']"
            placeholder="Укажите время"
            :options="timeSelectOptions"
          />

          <iq-select-form
            v-model="gettingSlotsForm['culture_id']"
            full-width
            label="Культура:"
            prop="culture_id"
            :options="preparedCultureList"
            :disabled="!gettingSlotsForm['unload_id'] || getLoading"
            placeholder="Укажите культуру"
            message="Для выбора культуры укажите терминал"
          />

          <iq-select-form
            v-model="gettingSlotsForm['truck_type_code']"
            full-width
            :disabled="!gettingSlotsForm['unload_id'] || getLoading"
            placeholder="Выберите тип авто"
            label="Тип авто:"
            prop="truck_type_code"
            :options="autoTypeRequired"
            message="Для выбора типа авто укажите терминал"
          />
          <iq-select-form
            v-model="gettingSlotsForm['supplier_id']"
            full-width
            placeholder="Укажите поставщика"
            label="Квота на поставщика"
            :options="supplierList"
            @input="setSupplier"
          />
          <template v-if="isRequireInnTerminal">
            <div class="m-t-20 m-b-7 requireInn__title">
              ИНН поставщика/перевозчика:
            </div>
            <div class="df requireInn">
              <el-form-item
                ref="requireInn"
                v-mask="'############'"
                prop="requireInn"
                class="requireInn__input"
                :rules="rulesForSupplier"
              >
                <el-autocomplete
                  v-model="gettingSlotsForm.requireInn"
                  popper-class="common-supplier"
                  placement="top-start"
                  placeholder="Укажите ИНН"
                  :fetch-suggestions="querySearchInn"
                  @input="setSupplierEdited"
                  @select="setCommonSupplier"
                >
                  <template #default="{ item }">
                    <span>{{ item.label }}&nbsp;</span>
                    <b>{{ item.value }}</b>
                  </template>
                </el-autocomplete>
              </el-form-item>
              <iq-button
                v-if="!isSupplierVerified"
                color="primary"
                @onClick="verifySupplier"
              >
                Подтвердить
              </iq-button>
              <iq-button
                v-if="requiredSupplier.verified && requiredSupplier.inn"
                @onClick="dropSupplier"
              >
                Изменить
              </iq-button>
            </div>
            <div
              v-if="isSupplierVerified"
              class="requireInn__name m-t-7 m-l-16"
            >
              {{ gettingSlotsForm.requireInnName }}
            </div>
          </template>
          <el-form-item style="margin-top: 32px">
            <iq-button
              :disabled="!isSupplierVerified"
              size="fullWidth"
              @onClick="submitForm('gettingSlotsForm')"
            >
              Запросить таймслот
            </iq-button>
          </el-form-item>
        </div>
      </el-collapse-transition>
    </el-form>
    <timeslots-left-modal
      :add-balace-request="addBalaceRequest"
      :dialog-visible="dialogVisible"
      :timeslots-left="timeslotsLeft"
      :timeslot-count-terminal="timeslotCountTerminal"
      :requests-count="gettingSlotsForm['requests_count']"
      @close="dialogVisible = false"
    />
  </div>
</template>

<script>
import { DATE_FORMAT, GET_TIME } from '@/constants/date'
import {
  DR_FETCH_AUTOCOMLETE_SUPPLIER,
  DR_FETCH_CULTURES,
  DR_FETCH_USER_PROFILE,
  DR_GET_AUTOCOMLETE_SUPPLIER,
  DR_GET_CULTURES,
  DR_GET_LOADINGS,
  DR_GET_TERMINALS,
  DR_GET_USER_PROFILE,
  DR_POST_TIME_REQUEST,
  DR_UPDATE_CURRENT_TIME,
  DR_UPDATE_RUN_TIMER,
  FETCH_EDITOR_TEXT,
  GET_EDITOR_TEXT,
  SET_TIMEZONE_OFFSET,
} from '@/store/actions'
import {
  FETCH_EXPORTERS_ALL,
  GET_EXPORTERS_ALL,
} from '@/views/control/store/actions'
import {
  FETCH_SUPPLIERS_ALL,
  GET_SUPPLIERS_ALL,
} from '@/views/exporter/store/actions'

import {
  DEFAULT_TIMEZONE,
  getDateUpdatedFromLocalTz,
  getTime,
  getTimeslotTzTime,
  getTzTime,
  isPickerDateToday,
  nowTz,
  pickerOptions,
} from '@/core'
import { autoTypeRequired } from '@/constants/auto-type'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { rulesFormGettingSlots } from '../data.js'
import { timeForSelect } from '@/constants/time'
import Api from '@/api'
import BalanceLeft from './BalanceLeft'
import IqButton from '../../ui/components/buttons/IqButton'
import IqRadioForm from '../../ui/components/radio/IqRadioForm'
import IqSelectDateForm from '../../ui/components/select/IqSelectDateForm'
import IqSelectForm from '../../ui/components/select/IqSelectForm'
import IqTitle from '../../ui/components/typography/IqTitle'
import TimeslotsLeftModal from './TimeslotsLeftModal'

export default {
  name: 'AddRequestSlots',
  components: {
    BalanceLeft,
    IqRadioForm,
    IqSelectForm,
    IqTitle,
    IqButton,
    IqSelectDateForm,
    TimeslotsLeftModal,
  },

  data() {
    // проверка наличия юрлица с указанным ИНН
    let validateSupplierInnExist = async (rule, value, callback) => {
      if (this.requiredSupplier.verified) {
        return callback()
      }
      this.isLoading = true
      try {
        const { data } = await Api.timeslots.getCommonSuppliersCheckInn({
          inn: value,
        })

        this.temporarySupplier = data
        callback()
      } catch (e) {
        callback(new Error('Указан несуществующий ИНН'))
      } finally {
        this.isLoading = false
      }
    }
    let validateSupplierInnLength = (rule, value, callback) => {
      if (value.length === 10 || value.length === 12) {
        callback()
      } else {
        callback(new Error('Неверный формат ИНН'))
      }
    }

    return {
      showInnModal: false,
      isStepTerminal: false,
      isRequireInnTerminal: false,
      preparedCultureList: [],

      addBalaceRequest: false,
      DATE_FORMAT,
      timeslotsLeft: 0,
      dataRequest: null,
      timeslotCountTerminal: null,
      dialogVisible: false,
      supplierList: [],
      pickerOptions,
      slotPrice: 0,
      slotCount: 0,
      autoTypeRequired,
      culture_id_from_store: null,
      gettingSlotsForm: {
        exporter_id: null,
        common_supplier_id: null,
        requireInn: null,
        requireInnName: null,
        requests_count: 1,
        time: null,
        day: null,
        unload_id: null,
        culture_id: null,
        supplier_id: null,
        truck_type_code: null,
      },
      dayUnix: null,
      rulesFormGettingSlots,
      requiredSupplier: {
        inn: null,
        verified: true,
      },
      temporarySupplier: null,
      rulesForSupplier: [
        {
          required: true,
          message: 'Укажите ИНН',
          trigger: 'submit',
        },
        { validator: validateSupplierInnLength, trigger: 'submit' },
        { validator: validateSupplierInnExist, trigger: 'submit' },
      ],
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      getCultures: DR_GET_CULTURES,
      getUserProfile: DR_GET_USER_PROFILE,
      getTerminals: DR_GET_TERMINALS,
      getSuppliers: GET_SUPPLIERS_ALL,
      getLoading: DR_GET_LOADINGS,
      getEditorText: GET_EDITOR_TEXT,
      getExporters: GET_EXPORTERS_ALL,
      commonSuppliers: DR_GET_AUTOCOMLETE_SUPPLIER,
    }),
    timeSelectOptions() {
      let minTime

      isPickerDateToday(this.gettingSlotsForm.day)
        ? (minTime = Number(getTzTime(nowTz(), GET_TIME).slice(0, 2)))
        : (minTime = 0)

      return timeForSelect(24).filter(
        item => Number(item.value.slice(0, 2)) >= minTime,
      )
    },

    isSupplierVerified() {
      return this.requiredSupplier.verified
    },
  },

  watch: {
    getCultures: {
      handler(value) {
        if (value) {
          this.preparedCultureList = value.map(item => ({
            id: item.id,
            unload_id: item.unload_id,
            name: `${item.name} ${
              item.harvest_year ? item.harvest_year + ' года' : ''
            }`,
            harvest_year: `${item.harvest_year}`,
          }))
        }
        this.getCultures?.map(item => {
          if (item.id === this.culture_id_from_store) {
            this.gettingSlotsForm.culture_id = this.culture_id_from_store
          }
        })
      },
      deep: true,
      immediate: true,
    },
    getExporters(val) {
      if (val.length === 1) {
        this.gettingSlotsForm.exporter_id = val[0].id
        this.selectSuppliers()
      } else {
        this.gettingSlotsForm.exporter_id = null
      }
    },
    getUserProfile(val) {
      this.timeslotsLeft = val.timeslots_left
    },
    'gettingSlotsForm.day'() {
      this.checkTime()
    },
    getSuppliers(val) {
      val
        ? (this.supplierList = [{ id: null, name: 'Без квоты' }, ...val])
        : (this.supplierList = [{ id: null, name: 'Без квоты' }])
    },
    'gettingSlotsForm.unload_id': {
      handler(val) {
        if (val) {
          localStorage.removeItem('get-slot-form')
        }
        if (val && this.getTerminals) {
          const currentTerminal = this.getTerminals.find(
            item => item.id === val,
          )
          // устанавливаем часовой пояс терминала для правильного отображения дейт- и таймпикеров
          let timezone = currentTerminal.timezone_utc_offset || DEFAULT_TIMEZONE

          localStorage.setItem('terminal-timezone', timezone)

          this.setTimezoneOffset(timezone)

          this.isStepTerminal =
            currentTerminal && currentTerminal.slug === 'step'

          this.isRequireInnTerminal = currentTerminal.require_inn

          this.timeslotCountTerminal =
            this.getTerminals &&
            this.getTerminals.find(item => item.id === val)[
              'use_timeslots_wallet'
            ]
        }
        this.gettingSlotsForm.culture_id = null
        this.gettingSlotsForm.supplier_id = null
        this.fetchEditorText({ unload_id: val })
        this.fetchCultures({ unload_id: val })
        this.fetchExporters(val)
        this.fetchCommonSuppliers()

        if (val) {
          this.$nextTick(() => {
            this.$refs.gettingSlotsForm.clearValidate('culture_id')
            this.$refs.gettingSlotsForm.clearValidate('truck_type_code')
            this.$refs.gettingSlotsForm.clearValidate('time')
            this.$refs.gettingSlotsForm.clearValidate('day')
          })
        }
      },
      deep: true,
      immediate: true,
    },
    getTerminals: {
      handler(val) {
        if (val && this.gettingSlotsForm.unload_id) {
          const currentTerminal = val.find(
            item => item.id === this.gettingSlotsForm.unload_id,
          )

          this.isStepTerminal =
            currentTerminal && currentTerminal.slug === 'step'

          this.timeslotCountTerminal = this.getTerminals.find(
            item => item.id === this.gettingSlotsForm.unload_id,
          )['use_timeslots_wallet']
        }
      },
      deep: true,
      immediate: true,
    },

    gettingSlotsForm: {
      handler() {
        localStorage.setItem(
          'get-slot-form',
          JSON.stringify(this.gettingSlotsForm),
        )
      },
      deep: true,
    },
  },
  mounted() {
    localStorage.removeItem('get-slot-form')
    this.fetchUserProfile()
  },
  methods: {
    ...mapActions({
      fetchUserProfile: DR_FETCH_USER_PROFILE,
      fetchEditorText: FETCH_EDITOR_TEXT,
      fetchCultures: DR_FETCH_CULTURES,
      fetchExporters: FETCH_EXPORTERS_ALL,
      fetchSuppliers: FETCH_SUPPLIERS_ALL,
      fetchCommonSuppliers: DR_FETCH_AUTOCOMLETE_SUPPLIER,
      postTimeRequest: DR_POST_TIME_REQUEST,
      updateCurrentTime: DR_UPDATE_CURRENT_TIME,
      runTimer: DR_UPDATE_RUN_TIMER,
    }),
    ...mapMutations({
      setTimezoneOffset: SET_TIMEZONE_OFFSET,
    }),
    selectSuppliers() {
      this.fetchSuppliers({
        id: this.gettingSlotsForm.unload_id,
        exporter_id: this.gettingSlotsForm.exporter_id,
        full_list: 0,
      })
    },

    // выбор поставщика по квоте
    async setSupplier(value) {
      // если терминал с обязательным ИНН, кидаем запрос для получения common_supplier_id, поскольку
      // оно не совпадает с supplier_id (разные таблицы на бэке
      // и уже полученный результат сохраняем в форме
      if (this.isRequireInnTerminal) {
        this.$refs.requireInn.clearValidate()

        if (value) {
          let supplier = this.getSuppliers.find(
            item => item.id === this.gettingSlotsForm.supplier_id,
          )

          this.isLoading = true

          const { data } = await Api.timeslots.getCommonSuppliersCheckInn({
            inn: supplier.inn,
          })

          this.isLoading = false
          this.requiredSupplier.inn = data.inn
          this.requiredSupplier.verified = true
          this.gettingSlotsForm.common_supplier_id = data.id
          this.gettingSlotsForm.requireInn = data.inn
          this.gettingSlotsForm.requireInnName = data.name
        } else {
          // если выбрано "Без квоты" - просто обнуляем данные
          this.dropSupplier()
        }
      }
    },

    setCommonSupplier(item) {
      this.$refs.requireInn.clearValidate()
      this.requiredSupplier.inn = item.label
      this.requiredSupplier.verified = true
      this.gettingSlotsForm.common_supplier_id = item.id
      this.gettingSlotsForm.requireInn = item.label
      this.gettingSlotsForm.requireInnName = item.value
    },

    // при изменении инпута поставщика по ИНН меняем
    // параметр необходимости верификации через БД
    setSupplierEdited() {
      this.requiredSupplier.inn = this.gettingSlotsForm.requireInn
      this.requiredSupplier.verified = false
    },

    verifySupplier() {
      this.$refs.gettingSlotsForm.validateField('requireInn', errorMessage => {
        if (!errorMessage) {
          this.gettingSlotsForm.requireInnName = this.temporarySupplier.name
          this.gettingSlotsForm.common_supplier_id = this.temporarySupplier.id
          this.requiredSupplier.inn = this.temporarySupplier.inn
          this.requiredSupplier.verified = true
        }
      })
    },
    // обнуляем данные поставщика по ИНН
    dropSupplier() {
      this.requiredSupplier.inn = null
      this.requiredSupplier.verified = true
      this.gettingSlotsForm.common_supplier_id = null
      this.gettingSlotsForm.requireInn = null
      this.gettingSlotsForm.requireInnName = null
    },

    addBalaceRequestHandle() {
      this.addBalaceRequest = true
      this.dialogVisible = true
    },
    checkTime() {
      if (
        getTime(this.gettingSlotsForm.day, DATE_FORMAT) ===
          getTime(nowTz(), DATE_FORMAT) &&
        Number(this.timeSelectOptions[0].id.slice(0, 2)) >
          Number(this.gettingSlotsForm['time']?.slice(0, 2))
      ) {
        this.gettingSlotsForm['time'] = this.timeSelectOptions[0].id
      }
    },
    // автопоиск для common_supplier
    querySearchInn(queryString, cb) {
      const results = queryString
        ? this.commonSuppliers.filter(this.createFilter(queryString))
        : this.commonSuppliers

      cb(results)
    },
    createFilter(queryString) {
      return link => link.label.indexOf(queryString) !== -1
    },
    getHoursMinutes(date, type) {
      if (type === 'hours') {
        const reg = /(\b[0-2]?\d):/

        return Number(date.match(reg)[1])
      }
      if (type === 'minutes') {
        const reg = /:([0-5]\d\b)/

        return Number(date.match(reg)[1])
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let getTimeSlot = getTimeslotTzTime(
            this.gettingSlotsForm.day,
            this.getHoursMinutes(this.gettingSlotsForm.time, 'hours'),
            this.getHoursMinutes(this.gettingSlotsForm.time, 'minutes'),
          )

          this.dataRequest = {
            ...this.gettingSlotsForm,
            time: getTimeSlot,
            day: getDateUpdatedFromLocalTz(this.gettingSlotsForm.day),
          }

          localStorage.setItem(
            'get-slot-form',
            JSON.stringify(this.gettingSlotsForm),
          )

          if (0 === this.timeslotCountTerminal) {
            this.postTimeRequest(this.dataRequest)
          } else {
            try {
              this.fetchUserProfile()
            } finally {
              this.timeslotsLeft - this.gettingSlotsForm.requests_count < -3
                ? (this.dialogVisible = true)
                : this.postTimeRequest(this.dataRequest)
            }
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="sass">
$bluColor: #4CA7D8
$redColor: #F56C6C
$mainFont: #606266
.iq-promt
  @media(max-width: 480px)
    width: 90%
.slot-time
  font-size: 14px
  padding: 0
  color: $mainFont
  margin: 0 0 5px 0
  font-weight: bold
  .blue
    color: $bluColor
.slot-day
  font-size: 16px
  color: $btn-orange!important
  font-weight: bold
.info-message-culture
  font-size: 11px
  color: $btn-orange!important
.radio-group-filter
  width: 100%
  display: flex
  justify-content: space-between
  .el-radio
    text-align: center
    padding: 5px
    width: 18%
    background: #FFFFFF!important
    box-sizing: border-box
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
    border-radius: 5px
    font-weight: 400
    color: #2D2D2D
    height: 26px
    border: none
    margin-right: 0
  .is-checked
    background: $btn-orange!important
    color: white!important
    .el-radio__label
      color: white!important
    font-weight: 500
    span
      padding-left: 0
    .el-radio__input
      display: none
  .el-radio__inner
    display: none
  span
    padding-left: 0
    .is-checked
      background: #67C23A!important
      color: white!important
.drivers-slot__info
  background: white
  font-weight: bold
  font-size: 14px
  line-height: 13px
  > span
    color: $btn-orange
.drivers-slot__card
  padding: 8px
.drivers-slot__warning
  text-align: center
  font-size: 16px
  font-weight: bold
  color: #DB6D39
.drivers-slot-btn
  margin-top: 16px
  background: $color-blue-light
  width: 100%
  padding: 12px 0px
  height: 45px
.drivers-main-container
  background: white
  margin-top: 40px
  padding: 0 15px
  max-height: 80vh
  overflow: scroll
  min-width: 320px
.drivers-slots-form
  background: white
  font-weight: normal
  font-size: $fs-14
  line-height: $lh-16
  position: relative
  .el-form-item
    &__label
      text-align: left
      line-height: 22px
      font-weight: bold
      width: 100%
      padding: 0 !important
  .el-date-editor
    width: 100% !important
  +to(550px)
    .el-form-item__label
      min-width: 300px!important
  .el-form-item__content
    line-height: 32px
  .el-select
    width: 100%
  .el-input__icon:before
    font-size: 14px
.line
  display: flex
  justify-content: center
  align-items: center

.requireInn
  align-items: flex-start
  gap: 30px
  &__input.el-form-item
    width: 200px
    margin-bottom: 0 !important
.requireInn__title
  color: #606266
.requireInn__name
  font-weight: 700
.common-supplier
  width: 345px !important
</style>
