var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "balance-slots" }, [
    _c("div", { staticClass: "balance-text" }, [
      _c("i", {
        staticClass: "el-icon-warning",
        staticStyle: { color: "#db6d39", "margin-right": "12px" },
      }),
      _vm._v(" На балансе доступно: "),
      _c("b", [_vm._v(" " + _vm._s(_vm.countLeft) + " тайм слотов ")]),
    ]),
    _c(
      "span",
      { staticClass: "balance-btn" },
      [
        _c(
          "iq-button",
          {
            attrs: { size: "small" },
            on: {
              onClick: function ($event) {
                return _vm.$emit("addBalance")
              },
            },
          },
          [_vm._v(" Пополнить баланс ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }