<template>
  <el-form-item class="iq-radio" :label="label" :prop="prop">
    <el-radio-group
      v-model="inputVal"
      :disabled="disabled"
      class="radio-group-filter iq-radio"
    >
      <el-radio
        v-for="item in options"
        :key="item.id || item"
        :label="keyLabel ? item[keyLabel] : item"
        class="iq-radio"
      >
        {{ keyText ? item[keyText] : item }}
      </el-radio>
    </el-radio-group>
  </el-form-item>
</template>

<script>
export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isFilterable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      require: true,
    },
    label: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    keyLabel: {
      type: String,
      default: '',
    },
    keyText: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="sass">
$color-blue-primary: #4CA7D8

.iq-radio
  .el-form-item__label
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: normal !important
    font-size: 14px !important
    line-height: 22px
    color: #606266
  .el-form-item__label:before
    content: '' !important
  .radio-group-filter
    width: 100%
    display: flex
    justify-content: space-between
    .el-radio
      display: flex
      justify-content: center
      align-items: center
      text-align: center
      padding: 1.3em 0.5em
      width: 18%
      box-sizing: border-box
      font-weight: 400
      height: 26px
      border: none
      background: $color-blue-primary !important
      opacity: 0.5
      color: #fff
      border-radius: 4px
      margin-right: 0
      box-shadow: none
    .is-checked
      background: $color-blue-primary !important
      opacity: 1
      color: white!important
      .el-radio__label
        color: white!important
      font-weight: 500
      span
        padding-left: 0
      .el-radio__input
        display: none
    .el-radio__inner
      display: none
    span
      padding-left: 0
      .is-checked
        background: #67C23A!important
        color: white!important
</style>
